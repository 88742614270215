.App {
  text-align: center;
  font-family: 'Inter', sans-serif;
}
body {
  background-color: #0071e3 !important;
}
.Btn {
  margin: 5px;
  background-color: #0071e3 !important;
}
.Btn:hover {
  background-color: #009ee5 !important;
}
.logo {
  width: 260px;
  padding: 0px 0px 0px 50px;
}
.navBar{
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
}
.leftDots {
  float: left;
  width: 200px;
}
.rightDots {
  float: right;
  width: 200px;
  margin-top: 25px;
}
.link{
  background-color: #f8f9fa !important;
  color: #0071e3 !important; 
  border: none !important;
  }
 button.link:hover{
   color: black !important;
 }

.link {
  background-color: #f8f9fa !important;
  color: gray !important;
  border: none !important;
  box-shadow: none !important;
}
.centerHeader{
  padding: 0px 0px 0px 140px;
  outline: none;
}
button.link:hover {
  color: black !important;
  box-shadow: none!important;
}
.headers {
  color: #f6fcfe;
  text-align: center;
  padding: 50px;
}
.hrForm {
  background-color: #f4f4f4;
  margin-top: 25px;
  padding: 25px;
}
.navBarBtns {
  margin: 5px;
  padding: 0px 50px 0px 200px;
}
.blueSection{
  display: flex;
  background-color: #0071e3;
  color: white
}
/* Founders Message Page */
.foundersMessagePage {
  padding: 50px;
  background-color: #fdfdfd;
  display: flex;
  text-align: left;
  flex-wrap: wrap;
  justify-content: space-between;
}
.founderImg{
  padding: 95px 0 0 100px;
}
.message{
  display: flex;
  flex-direction: column;
  width: 60%;
  padding: 100px 100px 100px 0px;
}
/* Loan Officer Benefits Page */
.LObenefitsPage {
  background-color: #f4f4f4;
  display: flex;
  text-align: left;
  flex-wrap: wrap;
  justify-content: space-between;
}
.benefitsContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 100px 100px 0 0
}
.blueSectionComp {
  display: flex;
  flex-wrap: wrap;
  background-color: #0071e3;
  color: white;
}
.compHeaders {
  display: flex;
  width: 100%;
  padding: 50px;
  color:white;
}
.oldSection {
  padding-left: 200px;
  width: 45%;
}
.newSection {
  padding-left: 200px;
  width: 45%;
}
.increaseSection {
  padding: 50px 50px 50px 510px;
  text-align: center;
  font-weight: bold;
}
/* Presentation Page */
.presentationPage{
  padding: 50px;
  background-color: #f4f4f4;
  display: flex;
  text-align: left;
  flex-wrap: wrap;
  border-radius: 15px;
  margin-top: 100px;
  justify-content: space-between;
}
/* Get Licensed */
.licensingPage {
  padding: 50px;
  background-color: #f4f4f4;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.licensingContainer{
  text-align: left;
  padding-left: 100px !important;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .leftDots {
    display: none;
  }
  .rightDots {
    display: none;
  }
  .logo {
    width: 175px;
    padding-left: 10px;
  }
  .homePage,
  .homePage video{
    width: 100%
  }
  .centerHeader{
    padding: 0
  }
  .navBarBtns{
    padding: 0;
  }
  .increaseSection,
  .licensingPage,
  .licensingContainer,
  .licensingHeaders,
  .message {
    width: 100% !important;
    padding: 10px !important;
  }
  .oldSection,
  .newSection,
  .blueSectionComp h3{
    width: 100% !important;
    flex-direction: column;
    padding-left: 50px
  }
  .LObenefitsPage{
    margin-bottom: 250px;
  }
  .benefitsImgs,
  .benefits,
  .blueSection,
  .compHeaders,
  .blueSectionComp{
    width: 100% !important;
    flex-direction: column;
  }
  .founderImg{
    padding: 0;
  }

}